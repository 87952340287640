





















































































































































































































































































































import DialogMixins from '@/mixins/DialogMixins';
import WidgetMixins from '@/mixins/WidgetMixins';
import { loadWidget, truncateContent } from '@/utils/helpers';
import Component, { mixins } from 'vue-class-component';
import { Getter, namespace } from 'vuex-class';
import moment from 'moment-timezone';
import { IAPIKeys, ISenderID, IUser } from '@/types/types';
import { Watch } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { numeric, required, min, max } from 'vee-validate/dist/rules';
import axios from 'axios';

const authModule = namespace('auth');
const apiKeyModule = namespace('apiKeys');
const smsModule = namespace('sms');
extend('required', {
  ...required,
  message: 'Field is required',
});
extend('numeric', {
  ...numeric,
  message: 'Invalid phone number provided',
});
extend('min', {
  ...min,
  message: `Invalid phone number provided`,
});
extend('max', {
  ...max,
  message: `Maximum phone number must be 13`,
});

@Component({
  name: 'PersonalizeSMS',
  components: {
    InternetConnection: loadWidget('pages/InternetConnection'),
    ToolBar: loadWidget('widgets/ToolBar'),
    SnackBar: loadWidget('widgets/SnackBar'),
    EmptyPage: loadWidget('pages/EmptyPage'),
    ProgressBar: loadWidget('widgets/CircularProgressLoader'),
    AddSMSSenderID: loadWidget(`widgets/AddSenderID`),
    ValidationProvider,
    ValidationObserver,
    SkeletonPreloader: loadWidget('widgets/SkeletonPreloader'),
    DeleteDialog: loadWidget('widgets/DeleteDialog'),
  },
  filters: {
    timestamp(value: string, format?: string) {
      return moment(value).format(format ?? 'Do MMMM, YYYY');
    },
    truncateContent,
  },
})
export default class PersonalizeSMS extends mixins(WidgetMixins, DialogMixins) {
  @Getter('getCreditBalance') creditBalance!: number;
  @Getter('getResetFormState') resetFormState!: boolean;
  @apiKeyModule.Getter('getAPIKeys') accessKeys!: IAPIKeys;
  @smsModule.Getter('getSenderIDList') smsIDList!: Array<
    ISenderID & { uuid: string; id: number; slug: string }
  >;
  @smsModule.Getter('getMessagePlaceholders') placeholders!: string[];
  @smsModule.Getter('getTemplateDownloadUrl') downloadUrl!: string;
  @smsModule.Getter('getSenderIDList') senderID!: ISenderID[];
  @authModule.Getter('getUserDetails') user!: IUser;

  content = '';
  contentCopy = '';
  currentCursorIndex = 0;
  search = '';
  placeholder = 0;
  fileToUpload: File | null = null;
  senderId: string | null = null;

  get filterPlaceholders(): string[] {
    return this.placeholders.filter(
      (placeholder) =>
        placeholder?.toLowerCase().indexOf(this.search?.toLowerCase()) > -1
    );
  }

  compose(ev: Event): void {
    this.content = (ev.target as HTMLInputElement).value;
  }

  addToTextBox(text: string): void {
    const textAreaId = document.getElementById(
      'content'
    ) as HTMLTextAreaElement;
    const formatText = `%${text}%`;
    textAreaId.focus();
    textAreaId.setRangeText(
      formatText,
      textAreaId.selectionStart,
      textAreaId.selectionEnd,
      'end'
    );
  }

  @Watch('content')
  onContentChange(): void {
    if (this.downloadUrl) {
      this.$store.commit('sms/UPDATE_TEMPLATE_URL', null);
    }
  }

  @Watch('resetFormState')
  onFormChange(payload: boolean): void {
    if (payload) {
      this.$store.commit('sms/UPDATE_TEMPLATE_URL', null);
      this.content = '';
      this.fileToUpload = null;
    }
    setTimeout(() => {
      this.$store.dispatch('resetFormValues', false, { root: true });
    }, 2000);
  }

  generateTemplate(): void {
    this.contentCopy = this.content;
    this.$store.dispatch('sms/generateCustomizeMessageTemplate', {
      message: this.content,
    });
  }

  downloadTemplate(): void {
    axios
      .get(this.downloadUrl, {
        responseType: 'blob',
      })
      .then((response) => {
        const blob = new Blob([response.data], {
            type: 'application/octet-stream',
          }),
          a = document.createElement('a');
        a.download = `${this.downloadUrl.split('/')?.pop()}`;
        a.href = URL.createObjectURL(blob);
        a.click();
        URL.revokeObjectURL(a.href);
        a.remove();
      })
      .catch((error) => {
        this.$store.dispatch(
          'snackBarMessage',
          error.response?.data?.message ??
            'An error occurred while trying to download',
          {
            root: true,
          }
        );
        this.$store.dispatch('snackBarVisibility', true, { root: true });
      });
  }

  sendSMS(): void {
    let formData = new FormData();
    formData.append(
      'file',
      this.fileToUpload as File,
      (this.fileToUpload as File).name
    );
    this.$store.dispatch('sms/personalizeSMS', {
      body: formData,
      senderId: this.senderId,
    });
  }

  created(): void {
    this.$store.dispatch('auth/me');
    this.$store.dispatch('sms/listSenderID', { isApproved: true });
    this.$store.dispatch('sms/listPlaceholders');
    this.$store.dispatch('checkCreditBalance');
  }
}
